var render = function render(){
  var _vm$sortedInstallatio, _vm$sortedInstallatio2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading || _vm.reloadingExtensions ? _c('v-app', {
    style: {
      background: _vm.$vuetify.theme.themes.light.background
    },
    attrs: {
      "id": "main"
    }
  }, [_c('v-main', {
    staticClass: "d-flex justify-center align-center",
    staticStyle: {
      "min-height": "100vh"
    }
  }, [_c('div', [_c('div', {
    staticClass: "text-center py-4"
  }, [_c('div', [_c('img', {
    staticClass: "mb-6",
    staticStyle: {
      "max-width": "74px"
    },
    attrs: {
      "alt": "learnics logo",
      "src": "/learnics-spinner.svg"
    }
  })]), _c('img', {
    staticClass: "mb-6",
    staticStyle: {
      "max-width": "270px"
    },
    attrs: {
      "alt": "learnics",
      "src": "/learnics-title.png"
    }
  })]), _c('div'), _c('ExpandablePanel', {
    staticClass: "body-2 text-center",
    attrs: {
      "value": _vm.troubleLoading && !_vm.reloadingExtensions
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('v-card', {
    staticStyle: {
      "max-width": "675px",
      "margin": "auto"
    }
  }, [_c('v-card-title', {
    staticClass: "headline teacherPrimary lightest--text justify-center",
    attrs: {
      "primary-title": ""
    }
  }, [_c('div', {
    staticClass: "d-flex align-start justify-center"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('v-icon', {
    attrs: {
      "color": "lightest"
    }
  }, [_vm._v("mdi-wifi-alert")])], 1), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticStyle: {
      "word-break": "normal"
    }
  }, [_vm._v(" Trying to Connect to Your " + _vm._s(_vm.tryingToConnectToExtensionTitle) + " Extension... ")])])])]), _c('v-card-text', {
    staticClass: "py-4 px-8"
  }, [_c('div', {
    staticClass: "body-1",
    staticStyle: {
      "font-size": "18px !important"
    }
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" It appears you've installed an extension, but we are having trouble connecting to it. ")]), _vm.user && _vm.installations && _vm.sortedInstallationIds.length > 0 ? _c('div', {
    staticClass: "mt-4"
  }, [_c('div', [_vm._v(" You can manually reload your extension to try to restore functionality, or we can try to help you troubleshoot the issue by attempting to automatically reload your extension. It may take a moment to reach your extension. ")])]) : _c('div', [_c('div', {
    staticClass: "pb-2"
  }, [_vm._v(" Please try restarting or reinstalling your " + _vm._s(_vm.tryingToConnectToExtensionTitle) + " extension to restore functionality. ")]), _c('div', {
    staticClass: "pb-2"
  }, [_vm._v(" Contact us at "), _c('a', {
    attrs: {
      "href": "mailto:support@learnics.com"
    }
  }, [_vm._v("support@learnics.com")]), _vm._v(" if you continue to have trouble after reloading your extension. We apologize for the inconvenience. ")])])])]), _c('v-divider'), _c('div', {
    staticClass: "pa-4"
  }, [((_vm$sortedInstallatio = _vm.sortedInstallationIds) === null || _vm$sortedInstallatio === void 0 ? void 0 : _vm$sortedInstallatio.length) > 0 ? _c('div', {
    staticClass: "mb-4"
  }, [_c('v-btn', {
    staticClass: "mt-4",
    attrs: {
      "block": "",
      "x-large": "",
      "disabled": _vm.reloadingExtensions,
      "color": "teacherPrimary lightest--text"
    },
    on: {
      "click": function ($event) {
        return _vm.tryToReloadExtensions();
      }
    }
  }, [_vm._v("Attempt to Reload My Extension")])], 1) : _vm._e(), _c('div', [_c('v-btn', {
    attrs: {
      "color": ((_vm$sortedInstallatio2 = _vm.sortedInstallationIds) === null || _vm$sortedInstallatio2 === void 0 ? void 0 : _vm$sortedInstallatio2.length) === 0 ? 'teacherPrimary lightest--text' : 'lightest teacherPrimary--text',
      "block": "",
      "x-large": ""
    },
    on: {
      "click": _vm.continueWithoutExtension
    }
  }, [_vm._v(" Continue without extension connectivity ")])], 1)])], 1)], 1)]), _c('ExpandablePanel', {
    attrs: {
      "value": !_vm.troubleLoading && !_vm.reloadingExtensions
    }
  }, [_c('div', {
    staticClass: "body-2 text-center"
  }, [_vm._v(_vm._s(_vm.loadingText))])]), _c('ExpandablePanel', {
    staticClass: "body-2 text-center",
    attrs: {
      "value": !!_vm.reloadingExtensions
    }
  }, [_c('div', {
    staticClass: "px-4"
  }, [_c('v-card', {
    staticStyle: {
      "max-width": "675px",
      "margin": "auto"
    }
  }, [_c('v-card-title', {
    staticClass: "headline teacherPrimary lightest--text justify-center",
    attrs: {
      "primary-title": ""
    }
  }, [_c('div', {
    staticClass: "d-flex align-start justify-center"
  }, [_c('div', {
    staticClass: "mr-3"
  }, [_c('v-icon', {
    attrs: {
      "color": "lightest"
    }
  }, [_vm._v("mdi-wifi-alert")])], 1), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticStyle: {
      "word-break": "normal"
    }
  }, [_vm._v(" Reloading Your " + _vm._s(_vm.tryingToConnectToExtensionTitle) + " Extension... ")])])])]), _c('v-card-text', {
    staticClass: "py-4 px-8"
  }, [_c('div', {
    staticClass: "body-1",
    staticStyle: {
      "font-size": "18px !important"
    }
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" We are attempting to reload your " + _vm._s(_vm.tryingToConnectToExtensionTitle) + " extension. It may take a moment to reach your extension. ")]), _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" If your extension fails to reload and refresh this page after about 30 seconds, you can try manually reloading your extension to restore functionality. ")]), _c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" To do so, visit the "), _c('strong', [_vm._v("chrome://extensions")]), _vm._v(" page by typing it into your browser's address bar, find the " + _vm._s(_vm.tryingToConnectToExtensionTitle) + " extension, and click the \"reload\" button next to the extension. You can also reinstall the extension - it's quick and easy and you won't lose any of your notes or logging sessions. ")])])])], 1)], 1)])], 1)])], 1) : _c('div', [_vm._t("default")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }